import React, { useMemo, useState } from "react"
import { arrayOf, func, number, shape, string } from "prop-types"
import { sortBy } from "lodash"
import { styled } from "@linaria/react"

import { StyledModal } from "src/styles/components/Modal"
import ModalBody from "src/styles/components/Modal/Body"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import PrimaryButton from "src/styles/components/Button/Primary"
import SearchableSelect from "src/components/SearchableSelect"

const defaultAgendaItemOption = {
  value: null,
  label: "Select agenda item"
}

const AssignAgendaItemModal = ({
  transcriptAgendaItems,
  handleAssignAgendaItem,
  close,
  className
}) => {
  const agendaItemsOptions = useMemo(
    () =>
      sortBy(transcriptAgendaItems, ["agendaItemsListIndex"]).map((item) => ({
        value: item.agendaItemId,
        label: `${item.agendaItemsListIndex}. ${item.name}`
      })),
    [transcriptAgendaItems]
  )

  const [selectedAgendaItem, setSelectedAgendaItem] = useState()

  const handleSubmit = () => handleAssignAgendaItem(selectedAgendaItem?.value).then(close)

  return (
    <StyledModal className={className} size="lg" show onHide={close}>
      <ModalHeader>
        <ModalTitle>Assign to agenda item</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SearchableSelect
          options={[defaultAgendaItemOption, ...agendaItemsOptions]}
          selected={selectedAgendaItem || defaultAgendaItemOption}
          setSelected={setSelectedAgendaItem}
          className="w-100"
        />
      </ModalBody>
      <ModalFooter>
        <PrimaryButton
          className="btn"
          width="md"
          onClick={handleSubmit}
          disabled={!selectedAgendaItem?.value}
        >
          Submit
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  )
}

AssignAgendaItemModal.propTypes = {
  transcriptAgendaItems: arrayOf(
    shape({
      agendaItemId: number.isRequired,
      agendaItemsListIndex: string.isRequired,
      name: string.isRequired
    })
  ).isRequired,
  handleAssignAgendaItem: func.isRequired,
  close: func.isRequired
}

export default styled(AssignAgendaItemModal)`
  .dropdown button,
  .dropdown-menu {
    width: 100%;
    max-width: none;
  }
`
