import React from "react"

import { Status } from "src/features/UniversalAi/constants"

import { ThreadItem as ThreadItemType } from "../propTypes"
import { QueryTypes } from "../constants"
import Query from "../Query"

import { YouLabel, KnowaQLabel, QueryContainer } from "./styles"
import Answer from "./Answer"
import SearchResults from "./SearchResults"
import LoadingState from "./LoadingState"
import ExternalContentAnswer from "./ExternalContentAnswer"

const ThreadItem = ({ threadItem }) => {
  const { query, queryType, answer, status, externalContentAnswer } = threadItem

  const isQueryType = queryType === QueryTypes.Query
  const isKeywordType = queryType === QueryTypes.Keyword
  const isLoading = status === Status.Loading

  const hasResponse = (isQueryType && answer?.length) > 0 || (isKeywordType && !isLoading)

  return (
    <div>
      <YouLabel>You</YouLabel>
      <QueryContainer>
        <Query
          query={query}
          queryType={queryType}
          focusableTitle={threadItem.focusable.title}
        />
      </QueryContainer>
      <KnowaQLabel>Knowa Q</KnowaQLabel>

      <LoadingState
        focusableTitle={threadItem.focusable.title}
        focusableType={threadItem.focusable.type}
        loading={isLoading}
        hasResponse={hasResponse}
      />

      {!isLoading && isQueryType && <Answer threadItem={threadItem} />}

      {externalContentAnswer && (
        <ExternalContentAnswer externalContentAnswer={externalContentAnswer} />
      )}

      {!isLoading && isKeywordType && <SearchResults threadItem={threadItem} />}
    </div>
  )
}

ThreadItem.propTypes = {
  threadItem: ThreadItemType.isRequired
}

export default ThreadItem
