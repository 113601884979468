import React, { useState, useMemo } from "react"
import { arrayOf, func, number, oneOfType, shape, string } from "prop-types"
import { useSelector } from "react-redux"
import { keyBy, omit } from "lodash"

import { millisecondsToMinutes } from "src/helpers/datetime"
import useIsOpened from "src/hooks/useIsOpened"
import { getAgendaItems } from "src/features/meetingPacks/MeetingPack/selectors"

import Utterance from "./Utterance"
import AgendaItem from "./AgendaItem"
import AssignAgendaItemModal from "./AssignAgendaItemModal"

const isDifferentSpeaker = (utterance, utterance2) => {
  let result = true

  if (utterance.speakerId || utterance2.speakerId)
    result = utterance.speakerId !== utterance2.speakerId
  else if (utterance.speaker) result = utterance.speaker !== utterance2.speaker

  return result
}

const Utterances = ({
  transcriptItems,
  setTranscriptItems,
  handleSave,
  handleSaveUtterances,
  handleAssignAgendaItem
}) => {
  const [editedUtteranceUid, setEditedUtteranceUid] = useState()
  const [assignAgendaItemObject, setAssignAgendaItemObject] = useState({})
  const {
    isOpened: isAssignAgendaItemModalOpened,
    open: showAssignAgendaItemModal,
    close: closeAssignAgendaItemModal
  } = useIsOpened()
  const meetingPackAgendaItems = keyBy(useSelector(getAgendaItems), "id")

  const handleOpenAssignAgendaItemModal = (utterance, index) => {
    setAssignAgendaItemObject({ utterance, index })
    showAssignAgendaItemModal()
  }

  const isAgendaItem = (transcriptItem) => !!transcriptItem.agendaItemId

  const showSpeaker = (item, item2) =>
    !item2 || isAgendaItem(item2) || isDifferentSpeaker(item, item2)

  const preparedTranscriptItems = useMemo(() => {
    let lastShownMinute

    return transcriptItems.map((item, i) => {
      if (isAgendaItem(item))
        return {
          item: {
            ...item,
            name: meetingPackAgendaItems[item.agendaItemId].name,
            agendaItemsListIndex: meetingPackAgendaItems[item.agendaItemId].index
          },
          index: i
        }

      const prevItem = i > 0 ? transcriptItems[i - 1] : null
      const showTimeline = showSpeaker(item, prevItem) && !!item.start
      const newGroupIndex = transcriptItems
        .slice(i + 1)
        .findIndex((el) => showSpeaker(item, el))
      const groupedUtterances =
        newGroupIndex >= 0
          ? transcriptItems.slice(i, i + newGroupIndex + 1)
          : transcriptItems.slice(i)
      const prevLastShownMinute = lastShownMinute

      if (showTimeline) {
        lastShownMinute = millisecondsToMinutes(item.start)
      }

      return {
        item,
        index: i,
        showTimeline,
        prevLastShownMinute,
        groupedUtterancesUids: groupedUtterances.map(({ uid }) => uid),
        showSpeaker: showSpeaker(item, prevItem)
      }
    })
  }, [transcriptItems])

  const transcriptAgendaItems = useMemo(
    () =>
      preparedTranscriptItems
        .filter((preparedItem) => isAgendaItem(preparedItem.item))
        .map((preparedItem) => preparedItem.item),
    [preparedTranscriptItems]
  )

  const dropOptions = {
    setTranscriptItems,
    handleSave: (item) => {
      const newTranscriptItems = transcriptItems.map((el) => {
        const isMovedItem = item.agendaItemId
          ? item.agendaItemId === el.agendaItemId
          : item.uid === el.uid
        return isMovedItem ? omit(el, ["start", "end"]) : el
      })
      setTranscriptItems(newTranscriptItems)
      handleSave(newTranscriptItems)
    }
  }

  const handleSaveUtterancesAndSetEdited = (utterancesData) => {
    const newUtterance = utterancesData.find((utteranceData) => utteranceData.index)
    if (newUtterance) setEditedUtteranceUid(newUtterance.uid)

    handleSaveUtterances(utterancesData)
  }

  return (
    <>
      {isAssignAgendaItemModalOpened && (
        <AssignAgendaItemModal
          transcriptAgendaItems={transcriptAgendaItems}
          handleAssignAgendaItem={(agendaItemId) =>
            handleAssignAgendaItem({ ...assignAgendaItemObject, agendaItemId })
          }
          close={closeAssignAgendaItemModal}
        />
      )}

      {preparedTranscriptItems.map((preparedItem) => {
        const { item, index } = preparedItem

        if (isAgendaItem(item))
          return (
            <AgendaItem
              key={item.agendaItemId}
              agendaItem={item}
              index={index}
              dropOptions={dropOptions}
            />
          )

        return (
          <Utterance
            key={item.uid}
            utterance={item}
            index={index}
            groupedUtterancesUids={preparedItem.groupedUtterancesUids}
            onSave={handleSaveUtterancesAndSetEdited}
            onAssignAgendaItem={handleOpenAssignAgendaItemModal}
            agendaItemsAssigned={!!transcriptAgendaItems.length}
            showSpeaker={preparedItem.showSpeaker}
            showTimeline={preparedItem.showTimeline}
            prevLastShownMinute={preparedItem.prevLastShownMinute}
            isEdited={editedUtteranceUid === item.uid}
            dropOptions={dropOptions}
          />
        )
      })}
    </>
  )
}

Utterances.propTypes = {
  transcriptItems: arrayOf(
    oneOfType([
      shape({
        uid: string.isRequired,
        text: string.isRequired,
        start: number,
        speaker: string,
        speakerId: number
      }),
      shape({
        agendaItemId: number.isRequired
      })
    ])
  ).isRequired,
  setTranscriptItems: func.isRequired,
  handleSave: func.isRequired,
  handleSaveUtterances: func.isRequired,
  handleAssignAgendaItem: func.isRequired
}

export default Utterances
