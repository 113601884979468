import React from "react"
import { Dropdown } from "react-bootstrap"
import { func, bool } from "prop-types"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import TridotIcon from "src/components/TridotIcon"
import { FONT_SIZES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"

const DropdownActions = ({
  onEdit,
  onDelete,
  showAssignSpeakerSelect,
  speakerShown,
  onAssignAgendaItem,
  agendaItemsAssigned,
  className
}) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />

      <Dropdown.Menu alignRight>
        <Dropdown.Item onClick={onEdit} className="no-wrap">
          <div className="dropdown-icon">
            <Icon type="pencil" size="small" />
          </div>
          Edit
        </Dropdown.Item>
        {agendaItemsAssigned && (
          <Dropdown.Item onClick={onAssignAgendaItem} className="no-wrap">
            <div className="dropdown-icon">
              <Icon type="add" />
            </div>
            Assign agenda item
          </Dropdown.Item>
        )}
        {!speakerShown && (
          <Dropdown.Item onClick={showAssignSpeakerSelect} className="no-wrap">
            <div className="dropdown-icon">
              <Icon type="add" />
            </div>
            Assign new speaker
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item
          className="text-red text-nowrap"
          onClick={onDelete}
          variant="danger"
        >
          <div className="dropdown-icon">
            <Icon type="cancel-circled" />
          </div>
          Delete paragraph
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

DropdownActions.propTypes = {
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  onAssignAgendaItem: func.isRequired,
  showAssignSpeakerSelect: func.isRequired,
  speakerShown: bool.isRequired,
  agendaItemsAssigned: bool.isRequired
}

export default styled(DropdownActions)`
  position: absolute;
  right: 10px;
  top: 10px;

  .dropdown-toggle {
    font-size: ${FONT_SIZES.medium};
    color: ${BASE_COLORS.lightGray};
    cursor: pointer;
  }

  .dropdown-item .dropdown-icon .icon-pencil {
    font-size: ${FONT_SIZES.medium};
  }
`
