import { oneOf, shape, string } from "prop-types"
import React from "react"

import { Status } from "src/features/UniversalAi/constants"

const ExternalContentAnswer = ({ externalContentAnswer }) => {
  const { status, answer } = externalContentAnswer

  if (status === Status.Loading) {
    return <h6 style={{ paddingTop: "40px" }}>Loading external content answer</h6>
  }

  if (!answer) return null

  return (
    <div style={{ paddingTop: "40px" }}>
      <h6>External content answer</h6>
      <div dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  )
}

ExternalContentAnswer.propTypes = {
  externalContentAnswer: shape({
    status: oneOf(Object.values(Status)),
    answer: string
  }).isRequired
}

export default ExternalContentAnswer
