import { useMutation } from "@apollo/client"
import { useCallback } from "react"

import useChannelName from "src/features/UniversalAi/Sidebar/useChannelName"
import { CHANNELS } from "src/constants/ably"
import { getCurrentUserId } from "src/helpers/user"
import useAblyChannel from "src/hooks/useAblyChannel"

import generateExternalContentAnswerMutation from "./generateExternalContentAnswerMutation.gql"

const useGenerateExternalContentAnswer = ({ focusedItem, onComplete, onLoad }) => {
  const channelName = useChannelName(
    `externalContentAnswer-${focusedItem?.type}`,
    CHANNELS.aiAnswer.externalContentAnswer
  )

  const [mutate] = useMutation(generateExternalContentAnswerMutation)

  const generateExternalContentAnswer = (query) => {
    const focusedItemInput = { id: focusedItem.id, type: focusedItem.type }
    mutate({ variables: { query, channelName, focusedItem: focusedItemInput } })
  }

  const onAblyMessage = useCallback(
    ({ data }) => {
      const isTargetObject =
        data.userId === getCurrentUserId() &&
        data.focusedItem.id.toString() === focusedItem?.id.toString() &&
        data.focusedItem.type === focusedItem?.type

      if (!isTargetObject) return

      if (!data.finished) onLoad()
      if (data.finished) onComplete(data.answer)
    },
    [focusedItem?.id, focusedItem?.type]
  )

  useAblyChannel(channelName, { onMessage: onAblyMessage })

  return generateExternalContentAnswer
}

export default useGenerateExternalContentAnswer
